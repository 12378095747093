import { Logout, Settings } from "@mui/icons-material";
import { Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { avatarStyle, blankLinkStyle, menuPaperProps, spacerSpanStyle } from "../util/styles";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../store/auth";

const NavMenu = () => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const user = useSelector(state => state.auth.user);

    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);

    const handleOpen = (e) => { setAnchorEl(e.currentTarget); };
    const handleClose = () => { setAnchorEl(null); };
    const handleLogout = () => {
        dispatch(authActions.logout());
        
        localStorage.clear();
        window.sessionStorage.clear();
        window.location.reload();
    };
    
    if (isAuthenticated) return ( 
        <Fragment>
            <Tooltip title="Account menu">
                <IconButton onClick={handleOpen} aria-label="Account">
                    <Avatar sx={avatarStyle}>
                        {user.username && user.username.charAt(0).toUpperCase()}
                    </Avatar>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={isOpen}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={menuPaperProps}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Link
                    style={blankLinkStyle}
                    to="/profile"
                >
                    <MenuItem>
                        <Avatar /> Profile
                    </MenuItem>
                </Link>
                <Link
                    style={blankLinkStyle}
                    to="/account"
                >
                    <MenuItem>
                        <Avatar /> My Account
                    </MenuItem>
                </Link>
                <Divider />
                <Link
                    style={blankLinkStyle}
                    to="/settings"
                >
                    <MenuItem>
                        <ListItemIcon>
                            <Settings fontSize="small" />
                        </ListItemIcon>
                        Settings
                    </MenuItem>
                </Link>
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </Fragment>
    );
    
    else return (
        <Typography variant="body2">
            <Link
                style={blankLinkStyle}
                to="/sign-in"
            >
                Sign In
            </Link>
            <span style={spacerSpanStyle} />
            <Link
                style={blankLinkStyle}
                to="/sign-up"
            >
                Sign Up
            </Link>
        </Typography>
    )
}
 
export default NavMenu;
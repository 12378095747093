import { Check } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { centerBoxStyle } from "../util/styles";

const checkmarkItem = (text) => (
    <Typography>
        <Check sx={{color: "green"}} />
        <span style={{ marginLeft: 10 }} />
        {text}
    </Typography>
);

const Contact = () => {
    return ( 
        <Box sx={centerBoxStyle}>
            <Typography
                component="h2"
                variant="h5"
            >
                Contact Us
            </Typography>
            <Typography variant="body2">
                By phone: call us at <a href="tel:18446893824">{"1 (844) 689-3824"}.</a>
            </Typography>
            <Typography variant="body2">
                By email: email us at <a href="mailto:1098tforms@herringbank.com">1098tforms@herringbank.com</a>
            </Typography>
            <Typography variant="body1">
                <b>Mailing Address:</b>
            </Typography>
            <Typography variant="body2">
                Herring Bank
            </Typography>
            <Typography variant="body2">
                2201 Civic Circle
            </Typography>
            <Typography variant="body2" sx={{ mb: 3 }}>
                Amarillo, TX 79109
            </Typography>
            
            <Typography variant="body1">
                <b>Students</b>
            </Typography>
            <Typography variant="body1">
                If you have any questions regarding downloading your 1098-T IRS tax form, please contact your college or university business office.
            </Typography>
            <Typography variant="body1">
                If you have any problems logging into the site please see our FAQ and then call support at 1-844-689-3824.
            </Typography>
            <Typography variant="body1">
                <b>Educators</b>
            </Typography>
            <Typography variant="body1">
                If you are interested in any of our higher education solutions, please contact our Sales team <a href="tel:18446893824">{"1 (844) 689-3824"}</a> or email us at <a href="mailto:1098tforms@herringbank.com">1098tforms@herringbank.com</a>.
            </Typography>
            <Box>
                <Typography
                    variant="body1"
                    sx={{ mt: 2, mb: 1 }}
                >
                    <b>ADDITIONAL CAMPUS SOLUTIONS</b>
                </Typography>
                {checkmarkItem("Student Deferred Payment Plans")}
                {checkmarkItem("Online Payments Processing")}
                {checkmarkItem("Wireless Merchant Terminal Processing")}
                {checkmarkItem("Student Designated Refund Account Choice")}
                {checkmarkItem("Student ID Cards – Financial Debit Cards or NON-Financial Cards")}
                {checkmarkItem("Meal Plan Systems and Closed Loop Wallets")}
            </Box>
        </Box>
     );
}
 
export default Contact;
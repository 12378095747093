import { Box, Typography } from "@mui/material";
import { centerBoxStyle } from "../../util/styles";
import image from '../../assets/images/article_images/4.jpg';

const CreditCardArticle = () => {
    return ( 
        <Box sx={centerBoxStyle}>
            <Typography
                component="h1"
                variant="h4"
                color="secondary"
                sx={{ mt: 2 }}
            >
                Should I Get a Credit Card in College?
            </Typography>
            <img src={image} alt="Should I get a credit card in college" />
            <Typography variant="body1">
                For many people, college is the first time they’re truly independent financially. Even if you are going to college and rely on your parents for financial support, the actual spending and managing of that money will fall on you.
            </Typography>
            <br />
            <Typography
                component="h2"
                variant="h4"
            >
                Pros:
            </Typography>
            <Typography variant="body1">
                <b>1. Credit Cards are Great for Emergencies</b> <br />
                One of the best things about a credit card at any age is that it works in an emergency situation. You may not always carry a lot of cash on you, or even have much in your bank account to use a debit card for, so a credit card can be a huge help in an emergency. If you plan on studying abroad, this is especially true. You never know when you may need to buy a meal, book a hotel, or make a big textbook payment that you’re not expecting and don’t have money on hand for. <br />
                <b>2. It Will Help You Establish Credit History</b> <br />
                Coming out of college with a credit history – a good one – is one of the best things you can do for yourself. Building up credit will make it easier to get future loans, take out a mortgage, or get another credit card with great rates. The earlier you start building this history, the better. <br />
                <b>3. It Allows You to Develop Money Management Skills</b> <br />
                Having a credit card forces you to learn how to make payments and monitor your spending. These money management skills will be important for the rest of your life, so it’s good to start on them now. It will also teach you about paying bills, late fee penalties, and monitoring bills for fraud. If you’re worried about forgetting to pay your bill, you can set up automatic bill pay from checking account if your bank offers that option.
            </Typography>
            <br />
            <Typography
                component="h2"
                variant="h6"
            >
                Cons:
            </Typography>
            <Typography variant="body1">
                <b>1. It Could Lead to a Low Credit Score</b> <br />
                If you’re not yet responsible enough to stay on top of payments, which many college students may not be, having a credit card could leave you with a bad credit score before you even graduate. If you know that you can’t keep up on payments, you might be doing yourself more harm than good by getting one. <br />
                <b>2. Credit Cards Have Interest Rates</b> <br />
                Though it is usually low, credit cards do come with interest rates. For a college student, even this small amount could be a big deal. <br />
                <b>3. They Make Saving Hard to Learn</b> <br />
                Though having a credit card will teach you all about credit, paying bills, and managing money, it doesn’t teach you much about saving up and can make it hard to track your spending. With a debit card or savings account, you have to be diligent about saving and spending, whereas, with a credit card, it’s easy to overspend and never really pay attention to your savings. <br />
                Looking for a credit card? Visit our <a href="https://www.herringbank.com/">Herring Bank website</a>!
            </Typography>
        </Box>
     );
}
 
export default CreditCardArticle;
import { Box, Typography } from "@mui/material";
import { centerBoxStyle } from "../util/styles";
import sampleFormImage from "../assets/images/2019_1098t_sample-1-1024x446.png"

const Updates = () => {
    return ( 
        <Box sx={centerBoxStyle}>
            <Typography
                component="h1"
                variant="h4"
                color="secondary"
            >
                1098-T Updates for 2022
            </Typography>
            <br></br>
            <Typography variant="body1">
                The Form 1098-T had only minimal changes for the 2019 tax year.  Box 3 of the form, which previously indicated that the your educational institution changed its reporting method, is now Reserved and will not be used in 2019.

                Depending on your income (or your family’s income, if you are a dependent), whether you were considered full or half-time enrolled, and the amount of your qualified educational expenses for the year, you may be eligible for a federal education tax credit. (You can find detailed information about claiming education tax credits in IRS Publication 970, page 9.)

                The dollar amounts reported on your Form 1098-T may assist you in completing IRS Form 8863 – the form used for calculating the education tax credits that a taxpayer may claim as part of your tax return.

                Herring Bank / Financial Payments is unable to provide you with individual tax advice, but should you have questions, you should seek the counsel of an informed tax preparer or adviser.

                Below is a sample of the 2019 Form 1098-T, for your general reference. Actual 1098Ts, if you qualify for one, will be available to view/print at <a href="www.1098tforms.com">www.1098tforms.com</a>. For more information about Form 1098-T, visit 
                <a href="https://www.irs.gov/pub/irs-pdf/f1098t_19.pdf"> https://www.irs.gov/pub/irs-pdf/f1098t_19.pdf</a>.
            </Typography>
            <img src={sampleFormImage} alt="sample form" />
            <Typography variant="body1">
                TAX FORM DETAILS
Box 1—Total payments from any source received by an eligible educational institution in 2019 for qualified tuition and related expenses less any reimbursements or refunds made during 2019 that relate to payments received during 2019. Please note the amount in Box 1 will NOT equal to or sum to the charges paid for calendar year 2019 because all charges are not considered qualified tuition and related expenses as defined by the Internal Revenue Service. For example, Health Services fees are not qualified. <br></br>

Note: If you registered for and paid for Spring 2019 classes in 2018, those payments will not be included on the 2019 1098-T. Also, if you register and pay for Spring 2020 classes in 2019, those payments will be included on the 2019 1098-T.<br></br>

Box 2—Reserved<br></br>

Box 3—Reserved<br></br>

Box 4—It is the amount of any adjustments made for a prior year for qualified tuition and related expenses that were reported on a prior year Form 1098-T. This amount may reduce any allowable education credit you may claim for the prior year. See Form 8863 or the IRS Pub 970 for more information. This box will be blank for 2019 as 2019 is the first year the University has reported payments.<br></br>

Box 5—Includes the total of all scholarships or grants administered and processed by the university. Spring 2020 scholarships or grants awarded in 2019 will appear in Box 5 and Box 1. <br></br>

Box 6—The amount of any adjustments made for prior year scholarships or grants that were reported on a prior year Form 1098-T. This amount may reduce any allowable education credit you can claim for the prior year. <br></br>

Box 7—Will be checked if the amount in Box 2 includes amounts for an academic period beginning January–March 2020. See the IRS Pub 970 for how to report these amounts. <br></br>

Box 8—Indicates whether you are considered to be carrying at least one-half the normal full-time workload for your course of study. <br></br>

Box 9—Indicates whether you are considered to be enrolled in a program leading to a graduate degree, graduate-level certificate, or other recognized graduate-level educational credential.<br></br>

Box 10—Will always be blank on any 1098-T form you receive.
            </Typography>
        </Box>
     );
}
 
export default Updates;
import logo from '../1098tLogoSmall.png';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography } from "@mui/material";
import { blankLinkStyle, fullSpacerSpanStyle, spacerSpanStyle } from '../util/styles';
import NavMenu from './NavMenu';

const navLink = (name, link) => (
    <React.Fragment>
        <span style={spacerSpanStyle} />
        <Link style={blankLinkStyle} to={link}>
            <Typography variant="h6">
                {name}
            </Typography>
        </Link>
    </React.Fragment>
)

const Navbar = () => {
    return ( 
        <AppBar>
            <Toolbar>
                <Link style={blankLinkStyle} to="/">
                    <img src={logo} aria-label="1098t Forms"/>
                </Link>
                {navLink("HOME", "/")}
                {navLink("2022 UPDATES", "/updates")}
                {navLink("PRODUCTS", "/products")}
                {navLink("ABOUT US", "/about")}
                <span style={fullSpacerSpanStyle} />
                <NavMenu />
            </Toolbar>
        </AppBar>
     );
}
 
export default Navbar;
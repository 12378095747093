import { Box, Typography } from "@mui/material";
import { centerBoxStyle } from "../../util/styles";
import image from '../../assets/images/article_images/2.jpg';

const SaveArticle = () => {
    return ( 
        <Box sx={centerBoxStyle}>
            <Typography
                component="h1"
                variant="h4"
                color="secondary"
                sx={{ mt: 2 }}
            >
                Can You Afford to Save?
            </Typography>
            <img src={image} alt="Can you afford to save" />
            <Typography variant="body1">
                Now would be a good time to look at doing a little spring cleaning with your finances, beginning with how to save some money for later use. This is the first in a multi-part series looking at the ins-and-outs of savings.
            </Typography>
            <br />
            <Typography
                component="h2"
                variant="h6"
            >
                Why and How You Can Build a Savings Plan
            </Typography>
            <Typography variant="body1">
                It is easier and a lot more fun to spend money than to save it. After all, what is the point of earning cash if you can’t enjoy it? Implementing an effective savings plan, however, is needed to ensure that you can enjoy that money now and in the future. You need to set aside some money, and you need to start doing it as soon as possible. <br />
                Begin building your savings plan by first understanding where your money is going and see where you can cut costs. Look at your daily, weekly, and monthly spending, and see where your money is being spent. Let’s say you eat out 5 times a week, spending an average of $10 dollars each time. If you cut that down to two times a week and set aside the $30 dollars saved for your savings plan, you’ll be saving $120 dollars a month. Not a bad start! <br />
                What about coffee? Can you cut some $3 dollar cups of coffee and enjoy some homemade cups for less than a buck? Do you really need another pair of tennis shoes? Can you move into a cheaper apartment? There are countless ways to save. The toughest part is establishing the willpower to do so.
            </Typography>
            <br />
            <Typography
                component="h2"
                variant="h6"
            >
                Start as Small as Needed
            </Typography>
            <Typography variant="body1">
                If you haven’t already implemented a savings plan, you need to start today. It doesn’t matter if you start with an old-fashioned piggy bank, or a coin jar, start right now. Set aside some amount of money. The ideal goal would be to start setting back 10% of your paycheck every payday but start wherever you can. Even if you’re just setting aside a dollar a day, you’re making headway, and you’re developing good habits. Your savings plan is your long-term money “funnel.” The money you save up can be used later for a new car, your golden years, or a down payment on a house. Of course, a dollar a day isn’t enough for a retirement plan, or even to build up an emergency fund. Both are vital and major reasons why you need to start your savings plan sooner rather than later. Tomorrow, we’ll look at both concepts, and explain why they are important. <br />
                In the meantime, check our our higher rates on savings accounts. Now is undoubtedly the best time to begin a savings plan. Give us a call today!
            </Typography>
        </Box>
     );
}
 
export default SaveArticle;
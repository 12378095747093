import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import { smallCenterBoxStyle } from "../util/styles";

const FAQ = () => {
    return ( 
        <Box sx={smallCenterBoxStyle}>
            <Typography
                component="h2"
                variant="h5"
            >
                FAQ
            </Typography>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="question-1"
                    id="question-1-header"
                >
                    <Typography>
                        What is the 1098-T Tax Tuition Form Service?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        1098-T Forms is a product of Herring Bank. This service is a system designed to allow colleges to provide students with a method to view and download their 1098-T Tax Tuition Form or receive their form by mail.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="question-2"
                    id="question-2-header"
                >
                    <Typography>
                        If I am a student and I have questions or problems who should I contact?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        You should contact the business office at your college.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="question-3"
                    id="question-3-header"
                >
                    <Typography>
                        Can I get my 1098-T from a previous year?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        If you used <a href="http://www.1098tforms.com">1098TForms.com </a>in a previous year, you will be able to access your 1098-T form from those years.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Box>
     );
}
 
export default FAQ;
import { Box, Grid, Typography } from "@mui/material";
import { blankLinkStyle, centerBoxStyle } from "../util/styles";
import image1 from '../assets/images/article_images/5-min-700x441.png';
import image2 from '../assets/images/article_images/1.jpg';
import image3 from '../assets/images/article_images/2.jpg';
import image4 from '../assets/images/article_images/e.jpg-700x441.png';
import image5 from '../assets/images/article_images/3.jpg'
import image6 from '../assets/images/article_images/4.jpg';
import { Link } from "react-router-dom";

const Articles = () => {
    const articles = [
        {
            title: "Budgeting 101: A 4-Step Process",
            description: "Going to college is your gateway into the adult world, and entry into the adult world requires learning how to handle your money like, well, an adult. As a college student, you might not [...]",
            image: image1,
            link: "/budgeting-101-a-4-step-process"
        },
        {
            title: "Boost Your Budget, Even in College!",
            description: "Living on a college budget funded by work-study checks alone can be tough. Surviving off a steady rotation of ramen noodles, PB&Js, and cereal can quickly get old. If you're wondering how college students can afford endless [...]",
            image: image2,
            link: "/boost-your-budget-even-in-college"
        },
        {
            title: "Can You Afford to Save?",
            description: "Now would be a good time to look at doing a little spring cleaning with your finances, beginning with how to save some money for later use. This is the first in a multi-part [...]",
            image: image3,
            link: "/can-you-afford-to-save"
        },
        {
            title: "Can Your Budget Withstand an Emergency?",
            description: "What’s an Emergency Fund and Why Does It Matter? One of the reasons you need to implement your savings plan is to start saving up money in case of an emergency. Your emergency fund is [...]",
            image: image4,
            link: "/can-your-budget-withstand-an-emergency"
        },
        {
            title: "Improving Your Credit Score in 5 Easy Steps",
            description: "Students often find themselves with less-than-perfect credit as they learn how to manage their money, but it doesn’t have to damage your financial future.  Your credit score is your financial history encapsulated in one three-digit [...]",
            image: image5,
            link: "/improving-your-credit-score-5-easy-steps"
        },
        {
            title: "Should I Get a Credit Card in College?",
            description: "For many people, college is the first time they’re truly independent financially. Even if you are going to college and rely on your parents for financial support, the actual spending and managing of that money will [...]",
            image: image6,
            link: "/should-i-get-a-credit-card-in-college"
        }
    ];
    
    return ( 
        <Box sx={centerBoxStyle}>
            <Typography
                component="h2"
                variant="h3"
                color="primary"
            >
                MONEY MANAGEMENT 101
            </Typography>
            <Typography
                component="h3"
                variant="h6"
            >
                QUICK READS ON HOW TO PLAN, SAVE AND SPEND RESPONSIBLY IN COLLEGE 
            </Typography>
            <Grid
                container
                spacing={2}
                sx={{ mt: 3 }}
            >
                {articles.map(article => (
                    <Grid item xs={12} lg={6} >
                        <Box sx={{ mt: 8 }}>
                            <Link
                                to={article.link || '#'}
                                style={blankLinkStyle}
                            >
                                <img src={article.image} alt="article" />
                                <Typography variant="h6">
                                    <b>{article.title}</b>
                                </Typography>
                                <Typography variant="body2">
                                    {article.description}
                                </Typography>
                            </Link>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
     );
}
 
export default Articles;
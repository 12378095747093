import Content from "./Content";

const BackgroundContainer = () => {

    return ( 
        <div style={{
            backgroundColor: '#FAF9F6',

            minHeight: '100%',

            backgroundAttachment: 'fixed',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        }}
        >
            <Content />
        </div>
     );
}
 
export default BackgroundContainer;
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const auth = createSlice({
    name: 'auth',
    initialState: {
        user: {},
        isAuthenticated: false,
        loading: false
    },
    reducers: {
        loginRequested(state, action) {
            state.loading = true;
        },
        loginSuccess(state, action) {
            localStorage.setItem('token', action.payload.idToken?.jwtToken);
            state.user = action.payload;
            state.isAuthenticated = true;
            state.loading = false;
        },
        loginFailed(state, action) {
            state.loading = false;
        },
        logout(state, action) {
            state.user = {};
            state.isAuthenticated = false;
        }
    }
});

const { loginRequested, loginSuccess, loginFailed } = auth.actions;

export const authActions = auth.actions;

export default auth.reducer;

const url = "/api/auth/signin";

export const loadUser = (credentials) => apiCallBegan({
    url,
    method: 'POST',
    data: credentials,
    onStart: loginRequested.type,
    onSuccess: loginSuccess.type,
    onError: loginFailed.type
});
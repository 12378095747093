import { Box, Typography } from "@mui/material";
import { centerBoxStyle } from "../../util/styles";
import image from '../../assets/images/article_images/e.jpg-700x441.png';

const EmergencyArticle = () => {
    return ( 
        <Box sx={centerBoxStyle}>
            <Typography
                component="h1"
                variant="h4"
                color="secondary"
                sx={{ mt: 2 }}
            >
                Can Your Budget Withstand an Emergency?
            </Typography>
            <img src={image} alt="Can your budget withstand an emergency" />
            <Typography
                component="h2"
                variant="h6"
            >
                What’s an Emergency Fund and Why Does It Matter?
            </Typography>
            <Typography variant="body1">
                One of the reasons you need to implement your savings plan is to start saving up money in case of an emergency. Your emergency fund is a stash of cash in a checking or savings account that you can tap into quickly if an emergency arises. <br />
                Emergencies happen. Your car might need a repair, your fridge could break, you may lose your job, or face an unexpected illness. Whatever the emergency is, make sure you’re prepared. If you don’t, you’ll find yourself running to a credit card or to a short-term, high-interest lender to take care of these situations. Once you do that, a minor situation can take years to pay off and end up costing you far more than the good or service is worth. Ideally, your emergency fund would cover three to six months of living expenses. At a bare minimum, you should strive to have $1,000 set aside. You can accomplish this with a good savings plan. <br />
                Apart from a systemized savings plan like we previously discussed, something as simple as rounding your checking account weekly or nightly can really pay rewards fast. For example, let’s say you have a balance of $453.28 in your checking account at the end of the day. Take that $3.28 and transfer it from your checking to savings that night. Even if you were only $0.68 over your $5, $10, or $20 rounding target, transfer it. It is not about the amount. It is about building a habit of saving. People who have used this method have been able to add anywhere from $50 to $130 each month to their savings without ever “feeling” it. If doing it each day is too much of a hassle, then choose a bigger target number like the nearest $20 or $50  and just do it once a week.
            </Typography>
            <br />
            <Typography
                compoent="h2"
                variant="h6"
            >
                How Your Savings Plan Will Help with Retirement
            </Typography>
            <Typography variant="body1">
                It doesn’t matter how young you are, you need to start saving for retirement as soon as possible. Your retirement plan will generally be made up of assets that are less liquid than your emergency fund. Think CD’s, stocks, bonds, and other investments – even establishing a trust in the future. These assets, however, almost always gain value over time. It is important to understand how compound interest rates work. This is a big topic in-and-of-itself and we cover it elsewhere. At the very least, know this: the value you gain will be growing at a much faster rate than you might imagine. <br />
                If you save $1,000 and gain ten percent interest, you’ll have $1,100 the next year. Then, let’s say that $1,100 gains 10% interest, so you end up with $1,210 the next year. In year one you gained $100. In year two you gained $110. Over time this can really add up. If you continue the plan described above for 40 years, you will have saved over $40,000 dollars personally but your account will be worth over a half-million dollars. This is why you need to start saving as soon as possible. <br />
                Today is the day to begin saving. Begin by looking at your budget and seeing where you can free up even a little bit of cash. Be creative and disciplined. Then build up an emergency fund to keep yourself out of debt. Finally, begin to invest for your future. If you want to start with an easy plan, then check out one of our Herring Bank savings accounts or CD’s. Either is available now at higher interest rates than before. Additionally, if you have been saving for a while and you are now wondering how to protect that money for future generations, you may want to contact a member of Herring Bank’s expanding Wealth Management Department. You can connect with them directly by calling <a href="tel:8062423744">(806) 242-3744</a>.
            </Typography>
        </Box>
     );
}
 
export default EmergencyArticle;
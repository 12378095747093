import axios from "axios";
import { toast } from "react-toastify";
import { baseURL } from "../../config";
import * as actions from '../api';

const api = ({ dispatch }) => next => async action => {
    if (action.type !== actions.apiCallBegan.type) return next(action);

    const { url, method, data, onStart, onSuccess, onError } = action.payload;
    
    if (onStart) dispatch({ type: onStart });

    let headers = {};
    let token = localStorage.getItem('token');
    if (token) headers = { "Authorization": "Bearer " + token };

    
    headers['app-security-key'] = '73GHcX06wOuMabdVWELMkH2zM';

    try {
        const response = await axios.request({
            baseURL,
            url,
            method,
            headers,
            data
        });

        dispatch(actions.apiCallSuccess(response.data));
        if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
    }
    catch (err) {
        dispatch(actions.apiCallFailed(err.message));
        toast.error(err.message);
        if (onError) dispatch({ type: onError, payload: err.message });
    }
};

export default api;
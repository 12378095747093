// import nodemailer from 'nodemailer';
import { Box, Button, Grid, TextField } from "@mui/material";
// import { nodemailerTransportOptions, receivingEmail } from '../config';
// import { toast } from 'react-toastify';

const Subscribe = () => {
    const handleSubscribe = async (e) => {
        e.preventDefault();

        // const { firstName, lastName, email, phone, institution, role } = e.cuurentTarget;

        // try {
        //     const testAccount = await nodemailer.createTestAccount();

        //     const transporter = nodemailer.createTransport({
        //         ...nodemailerTransportOptions,
        //         auth: {
        //             user: testAccount.user,
        //             password: testAccount.pass
        //         }
        //     });

        //     const info = await transporter.sendMail({
        //         from: `"${firstName.value} ${lastName.value} <${email.value}>`,
        //         to: receivingEmail,
        //         subject: `Sign up inquiry from "${firstName.value} ${lastName.value} <${email.value}>`,
        //         text:
        //             `Name: ${firstName.value} ${lastName.value}\n
        //             Email: ${email.value}\n
        //             Phone #: ${phone.value}\n
        //             Educational Instutution: ${institution.value}\n
        //             Role/Title: ${role.value}\n
        //             \n
        //             \n
        //             **Sent by 1098 React Website.`
        //     });

        //     return info;
        // }
        // catch (err) {
        //     toast.error(err);
        // }
    };

    return ( 
        <Box
            component="form"
            onSubmit={handleSubscribe}
            sx={{ mt: 1 }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        fullWidth
                        id="firstName"
                        label="First Name"
                        name="firstName"
                        autoComplete="given-name"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        autoComplete="family-name"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        id="phone"
                        label="Phone Number"
                        name="phone"
                        autoComplete="phone-number"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        id="institution"
                        label="Educational Institution"
                        name="institution"
                        autoComplete="school"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        id="role"
                        label="Title / Role"
                        name="role"
                        autoComplete="role"
                    />
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Submit
                </Button>
            </Grid>
        </Box>
     );
}
 
export default Subscribe;
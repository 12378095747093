import { Box, Typography } from "@mui/material";
import { centerBoxStyle } from "../util/styles";
import image1 from "../assets/images/intructions_images/tostudentenroll.png";
import image2 from "../assets/images/intructions_images/Picture1.png";
import image3 from "../assets/images/intructions_images/Picture2.png";
import image4 from "../assets/images/intructions_images/1098tforms_com_-_Student_Enrollment_Steps.png";
import image5 from "../assets/images/intructions_images/Picture4.png";
import image6 from "../assets/images/intructions_images/Picture5.png";
import image7 from "../assets/images/intructions_images/1098tforms_com_-_Student_Enrollment_Steps (1).png";
import image8 from "../assets/images/intructions_images/1098tforms_com_-_Student_Enrollment_Login.png";
import image9 from "../assets/images/intructions_images/Base_login.png";
import image10 from "../assets/images/intructions_images/Picture10-2.png";
import image11 from "../assets/images/intructions_images/acceptconsent.png";
import image12 from "../assets/images/intructions_images/viewDLform.png";
import image13 from "../assets/images/intructions_images/2019_1098t_sample-1-1024x446.png";

const Instructions = () => {
    return ( 
        <Box sx={centerBoxStyle}>
            <Typography
                component="h1"
                variant="h4"
                color="secondary"
                sx={{ m: 2 }}
            >
                1098-T Student Enrollment and Download Instructions
            </Typography>
            <Typography variant="body1">
                Below are instructions for accessing 1098-T IRS Tax Forms for students attending a college partnering with Herring Bank for 1098-T Processing. Protecting student financial information is extremely important and requires multiple steps to verify the student’s identity and email address so that only approved individuals can access, view, print or download a 1098-T IRS Tax Form.
            </Typography>
            <Typography variant="body1">
                If at any time the process displays an error, it is best to close out of your browser completely and begin again with a <b>NEW USERNAME</b>. Internet Explorer has caused several challenges for Enrollment, so we suggest using another web browser, such as Chrome, Firefox, or Safari.
            </Typography>

            <Typography
                component="h2"
                variant="h5"
                color="secondary"
                sx={{ mt: 2 }}
            >
                STEP 1
            </Typography>
            <Typography variant="body1">
                Go to the 1098tforms.com website and click on the black rectangle with instructions:
            </Typography>
            <Typography variant="body1">
                <b>TO STUDENT ENROLLMENT PAGE.</b>
            </Typography>
            <img src={image1} alt="Step 1" />

            <Typography
                component="h2"
                variant="h5"
                color="secondary"
                sx={{ mt: 2 }}
            >
                STEP 2
            </Typography>
            <Typography variant="body1">
                The Student Enrollment Page appears, <b>HELP US FIND YOUR STUDENT INFORMATION</b> by entering your first name, last name and social security number in the relevant boxes.
            </Typography>
            <Typography variant="body1">
                Please click the <b>VERIFY INFORMATION</b> box/link.
            </Typography>
            <img src={image2} alt="Step 2" />
            
            <Typography
                component="h2"
                variant="h5"
                color="secondary"
                sx={{ mt: 2 }}
            >
                STEP 3
            </Typography>
            <Typography variant="body1">
                You will be prompted: <b>CONGRATULATIONS! WE FOUND YOUR INFORMATION</b> on the next page. Please enter a valid Email Address. Then create a new Username and Password. You will need to remember this information to login in the future.
            </Typography>
            <Typography variant="body1">
                Then click the <b>ENROLL NOW</b> link.
            </Typography>
            <img src={image3} alt="Step 3" />

            <Typography
                component="h2"
                variant="h5"
                color="secondary"
                sx={{ mt: 2 }}
            >
                STEP 4
            </Typography>
            <Typography variant="body1">
                The message below will appear.
            </Typography>
            <Typography variant="body1">
                <b>DO NOT CLICK on step 6 CLICK HERE yet!</b>
            </Typography>
            <Typography variant="body1">
                First, check the inbox of the email address which was used to set up your 1098-T account, including the “Junk Mail” folder, for an email requesting Verification.
            </Typography>
            <img src={image4} alt="Step 4" />

            <Typography
                component="h2"
                variant="h5"
                color="secondary"
                sx={{ mt: 2 }}
            >
                STEP 5
            </Typography>
            <Typography variant="body1">
                Next, open the email with <b>“1098Tforms.com Student Enrollment Account Verification”</b> in the subject heading.
            </Typography>
            <Typography variant="body1">
                The email will be sent from no-reply@verificationemail.com
            </Typography>
            <Typography variant="body1">
                Upon opening, the email should look like the below.
            </Typography>
            <Typography variant="body1">
                After reading it, click on the <b>VERIFY EMAIL</b> link.
            </Typography>
            <img src={image5} alt="Step 5" />

            <Typography
                component="h2"
                variant="h5"
                color="secondary"
                sx={{ mt: 2 }}
            >
                STEP 6
            </Typography>
            <Typography variant="body1">
                The following image will appear by clicking the <b>VERIFY EMAIL</b> link. The image confirms that your registration has been confirmed.
            </Typography>
            <img src={image6} alt="Step 6" />

            <Typography
                component="h2"
                variant="h5"
                color="secondary"
                sx={{ mt: 2 }}
            >
                STEP 7
            </Typography>
            <Typography variant="body1">
                You may now return to the Enrollment screen and click the step 6 <b>CLICK HERE</b>.
            </Typography>
            <img src={image7} alt="Step 7" />

            <Typography
                component="h2"
                variant="h5"
                color="secondary"
                sx={{ mt: 2 }}
            >
                STEP 8
            </Typography>
            <Typography variant="body1">
                You will arrive at a page in which you can return to the previous page or click the button to <b>LOGIN NOW</b> which you should click only if you have registered your email correctly.
            </Typography>
            <img src={image8} alt="Step 8" />

            <Typography
                component="h2"
                variant="h5"
                color="secondary"
                sx={{ mt: 2 }}
            >
                STEP 9
            </Typography>
            <Typography variant="body1">
                You may now enter your <b>USERNAME</b> and <b>PASSWORD</b> for the account, then click on the <b>SIGN IN</b> link.
            </Typography>
            <img src={image9} alt="Step 9" />

            <Typography
                component="h2"
                variant="h5"
                color="secondary"
                sx={{ mt: 2 }}
            >
                STEP 10
            </Typography>
            <Typography variant="body1">
                Your enrollment is complete, and you’ve <b>LOGGED IN</b> successfully. Now you must agree to the conditions set out in the Electronic Consent form for 1098-T. Click on the <b>AGREE</b> link at the bottom of the page. <i>(The image below does not show the entire consent form)</i>
            </Typography>
            <img src={image10} alt="Step 10 part 1" />
            <img src={image11} alt="Step 10 part 2" />

            <Typography
                component="h2"
                variant="h5"
                color="secondary"
                sx={{ mt: 2 }}
            >
                STEP 11
            </Typography>
            <Typography variant="body1">
                Check the name of the college/university you attend in the window below. If it is correct, click the <b>VIEW/DOWNLOAD</b> link.
            </Typography>
            <img src={image12} alt="Step 11" />

            <Typography
                component="h2"
                variant="h5"
                color="secondary"
                sx={{ mt: 2 }}
            >
                STEP 12
            </Typography>
            <Typography variant="body1">
                Your 1098-T form will appear in a PDF. You may view, print or download the form at this time. <i>(Sample form shown below)</i>
            </Typography>
            <img src={image13} alt="Step 12" />
        </Box>
     );
}
 
export default Instructions;
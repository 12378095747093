import { Box, Typography } from "@mui/material";
import { centerBoxStyle } from "../../util/styles";
import image from '../../assets/images/article_images/3.jpg';

const CreditScoreArticle = () => {
    return ( 
        <Box sx={centerBoxStyle}>
            <Typography
                component="h1"
                variant="h4"
                color="secondary"
                sx={{ mt: 2 }}
            >
                Improving Your Credit Score in 5 Easy Steps
            </Typography>
            <img src={image} alt="Improving your credit score" />
            <Typography variant="body1">
                Students often find themselves with less-than-perfect credit as they learn how to manage their money, but it doesn’t have to damage your financial future. <br />
                Your credit score is your financial history encapsulated in one three-digit number. It affects whether you can get loans, how easy it is to rent an apartment, and what kind of interest rate you get when you apply for a mortgage. You can keep your credit score under control by following these few simple steps to better credit.
            </Typography>
            <br />
            <Typography
                component="h2"
                variant="h6"
            >
                Step #1: Get Your Credit Report
            </Typography>
            <Typography variant="body1">
                Once per year, you are entitled to receive a copy of your credit report for free from each of the two main credit bureaus. The number on your report will tell you whether your credit score is high, average, or poor. The report will show negative incidences from late or unpaid bills. If you’re having trouble understanding your credit score, ask someone at your bank for help. Your bank representative can also help you find solutions to raise your score to where you want it.
            </Typography>
            <br />
            <Typography
                component="h2"
                variant="h6"
            >
                Step #2: Dispute Mistakes
            </Typography>
            <Typography variant="body1">
                Don’t let mistakes on your credit report stay there. The financial industry is big and complex, and it is not unusual for a debt to be reported incorrectly. At times, even on-time payments can be reported as late. If you keep good records of your payments, getting the mistakes removed is usually a fairly simple matter of calling the debt issuer and providing some kind of proof that you made the payments when you think you did. If you’re friendly on the phone and have a good history with a particular lender, you might even be able to persuade them to remove one or two truly late payments from your report, especially if you can promise a large payment in the near future.
            </Typography>
            <br />
            <Typography
                component="h2"
                variant="h6"
            >
                Step #3: Get a Line of Credit
            </Typography>
            <Typography variant="body1">
                You can’t improve your credit score if you don’t have any credit to work with. Credit cards can be easy to abuse, but responsibly using a line of credit is one of the simplest and most straightforward ways to build a positive credit history. If your credit score is already high, you can find a credit card with benefits like air miles or cash back, but even if you are trying to improve a poor credit score, there are options available. Many banks offer a secured credit card as an option for rebuilding credit, which gives you a line of credit equal to a security deposit you pay to the bank. As you use the line of credit responsibly, you can get the credit increased or get your deposit returned, though this usually takes at least a couple of years of responsible spending and repayment.
            </Typography>
            <br />
            <Typography
                component="h2"
                variant="h6"
            >
                Step #4: Use Your Line of Credit Wisely
            </Typography>
            <Typography variant="body1">
                Your credit score is not just calculated based on whether you pay your debts and bills on time. It also takes into account how much debt you have, and how much of your available credit you are using. The more credit you use, the greater a financial risk you are, so try never to use more than 30% of your available credit at any given time. It’s easier to repay and better for your score when your creditor reports it. It will also leave you plenty of credit in the case of a genuine emergency.
            </Typography>
            <br />
            <Typography
                component="h2"
                variant="h6"
            >
                Step #5: Pay on Time Every Time
            </Typography>
            <Typography variant="body1">
                A line of credit is only as useful to your credit score as your ability to make timely payments, so make sure that you are making your credit card payments on time. If you are scatterbrained and prone to forget, most banks will let you set up automatic payments on your credit card, so you are guaranteed to pay on time as long as you have enough money to make the payment. To really make your credit card work for you, set up an automatic payment of a couple of household bills on your credit card, then set up an automatic payment from your bank account to your credit card: paying bills on time every month also has a positive effect on your credit score. <br />
                If you, like many others, have a low credit score or no credit at all, us about our Fresh Start card – a secured card that will help you rebuild your credit score, eventually increase your limits, and get you back on track.
            </Typography>
        </Box>
     );
}
 
export default CreditScoreArticle;
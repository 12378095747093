export const themeOptions = {
  palette: {
    type: 'dark',
    primary: {
      main: '#7ac8de',
    },
    secondary: {
      main: '#de907a',
    },
  },
};
import { Box, Typography } from "@mui/material";
import { centerBoxStyle } from "../../util/styles";
import image from '../../assets/images/article_images/1.jpg';

const BoostArticle = () => {
    return ( 
        <Box sx={centerBoxStyle}>
            <Typography
                component="h1"
                variant="h4"
                color="secondary"
            >
                Boost Your Budget, Even in College!
            </Typography>
            <img src={image} alt="Boost your budget" />
            <Typography variant="body1">
                Living on a college budget funded by work-study checks alone can be tough. Surviving off a steady rotation of ramen noodles, PB&Js, and cereal can quickly get old. If you’re wondering how college students can afford endless adventures and good times that the colleges of Hollywood promised, it sounds like it’s time to take a good, hard look at exactly where your money is going. Believe it or not, with a few small changes in your spending habits, you’ll be surprised how much money you can save and how easy cutting your spending can be. 
            </Typography>
            <br />
            <Typography
                component="h2"
                variant="h6"
            >
                Pack a Lunch
            </Typography>
            <Typography variant="body1">
                If you are pulling at least one meal a day through a drive-through window, you are probably spending much more money than necessary on food. Sure, the convenience of swinging through a fast food restaurant and feasting off the dollar menu can be justified… sometimes. But add to that the feeling of, “I deserve this,” and your budget (as well as your diet) will be in danger. Instead, try visiting the grocery store once a week to load up on foods you can pack for lunches ahead of time. You’ll soon notice that a package of turkey meat, some sliced cheese, a loaf of bread and a sack of fresh fruit can go a long way. Not only will your bank account benefit from packing your lunches, your overall health will benefit, too. Saving an extra $10 each week and avoiding the scourge of the “Freshman 15?” Win-win.
            </Typography>
            <br />
            <Typography
                component="h2"
                variant="h6"
            >
                Lose the Latte
            </Typography>
            <Typography variant="body1">
                You may love picking up a latte, energy drink, or other beverage on your way to work or school each morning, but do you really need it? <br></br>
                Home-brewed coffee can put the same “pep” in your step as an over-priced coffee from a local coffee shop, and if you learn to make your perfect cup at home, it may even become your preference. Eliminating luxuries can make a big difference to your college budget or savings plan. For example, a Grande Vanilla Latte from Starbucks averages $4.67. If you bought one of these every weekday for 18 weeks a semester, it would add up to over $826 a year!<br></br>
                <b>Bottled water</b> is another example of an extra expense. You may recall your parents whining about gas prices in the late 00’s when it was over $4.00 a gallon. But, if you buy bottled water at an average price of $1.59 per 16-20 oz. size, you are spending over $12.00 a gallon for something that comes free from the tap.  Instead, filter your own and keep it in a reusable bottle. You may even be helping the environment by cutting down on the massive amount of plastic discarded every day.
            </Typography>
            <br />
            <Typography
                component="h2"
                variant="h6"
            >
                Get on the Bandwagon…or at least a Bus
            </Typography>
            <Typography variant="body1">
                Hopping in your car, commuting to campus, and parking is the normal routine for thousands of students. If you took public transportation, carpooled, or used university transit instead, you’d save daily money on gas and parking fees, not to mention wear and tear on your vehicle. It also lets you relax, read, or catch up on your studying during your commute time. In fact, the U.S. saves 85 million gallons of gas per year by carpooling. Live close to campus? Why not ride your bike, get some exercise, and ditch parking woes and gas expenses all together? It could even help you get from class to class more efficiently as most campuses have bike racks much closer than parking lots to your destination building.
            </Typography>
            <br />
            <Typography
                component="h2"
                variant="h6"
            >
                Memberships are Costly
            </Typography>
            <Typography variant="body1">
                You had great goals when you joined the gym, but how often do you really use your membership? Instead of running on a treadmill and paying for it, you can run in a park or in your neighborhood for free. Many campuses also have gyms which you are already paying for and will provide you access. Even better, many campus gyms have trainers available for free in the form of fellow students who are working on degrees in Physiology, Kinesiology, or PE. This can save you anywhere from 20-40 dollars per month in most cases.<br></br>
                While we are on the topic of memberships, let’s think about at a few others as well. Subscriptions such as Hulu, Pandora, Spotify, Netflix, BirchBox and more all seem harmless, but add up those monthly $9.99 fees and they really start to take a toll on your bank account. Think about any memberships you have to make sure you are actually benefiting from them. If not, cancel them and save your money!
            </Typography>
            <br />
            <Typography
                component="h2"
                variant="h6"
            >
                Unplug It
            </Typography>
            <Typography variant="body1">
                If you live off campus, you control some of your biggest monthly expenses. Have you ever heard of “Vampire Gadgets”? These are devices that continue to drain electricity even when they are turned off, and they may be sucking your budget dry! Electronics like phone chargers, microwaves, televisions, and computers use energy all the time. The solution is simple: completely unplug them from the wall when you’re not using them. This can save up to 10% on your electric bill and is a good environmental practice in saving electricity.<br></br>
                Monitor your eStatement or online/mobile banking history to see where your money goes regularly. It will be simple to see where you are spending cash irresponsibly. College years are, in part, meant to be spent learning to budget your money. If you take the time to monitor your spending and your available funds, you’ll find more room in your budget for fun expenses and savings. If you have questions about online banking, mobile banking, or anything else that may impact your budget, contact us or visit our site: <a href="https://www.herringbank.com/">Herring Bank</a>.
            </Typography>
        </Box>
     );
}
 
export default BoostArticle;
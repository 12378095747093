import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Copyright = (props) => {
    return ( 
        <Box
            component="footer"
            sx={{
                py: 3,
                px: 2,
                mt: 'auto'
            }}
        >
            <Typography
                variant="body2"
                color="text.secondary"
                align="center"
                {...props}
            >
                {"| ©2020 Herring Bank | "} 
                <a href="http://www.herringbank.com/privacy-policy/">
                    Privacy Statement |
                </a>
                {" "}
                <Link to="/about">
                    Support |
                </Link>
                {" "}
                <Link to="/about">
                    Contact
                </Link>
            </Typography>
        </Box>
     );
}
 
export default Copyright;
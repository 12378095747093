import { Box, Typography } from "@mui/material";
import { centerBoxStyle } from "../util/styles";
import Contact from "./Contact";
import FAQ from "./FAQ";

const About = () => {
    return ( 
        <Box sx={centerBoxStyle}>
            <Typography
                component="h1"
                variant="h4"
                color="secondary"
                sx={{ mt: 2 }}
            >
                About Us
            </Typography>
            <Typography variant="body1">
                1098Tforms is provided by Herring Bank and powered by Financial Payments.
            </Typography>
            <br />
            <Typography variant="body1">
                Herring Bank is a community bank headquartered in Amarillo, Texas. The bank provides a full range of commercial and consumer banking products, as well as investment and trust services. While the bank is primarily owned by the family of its founder, Col. C.T. Herring, the bank has directors and owners in communities that it serves. Through local leadership and ownership, Herring Bank has created a legacy of helping customers with their financial needs for some 110 years.
            </Typography>
            <br />
            <Typography variant="body1">
                Financial Payments is a professional software company located in Amarillo, Texas, that specializes in systems integration and data processing of payment transactions. Financial Payments offers a variety of products and services to businesses, banks, and higher education institutions. Financial Payments was started in 2001 and is an affiliate of Herring Bank.
            </Typography>
            <FAQ />
            <Contact />
        </Box>
     );
}
 
export default About;
import { ManageAccountsOutlined } from "@mui/icons-material";
import { Avatar, Backdrop, Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getUsername } from "../store/users";
import { backdropStyle, centerBoxStyle, iconAvatarStyle } from "../util/styles";

const ForgotUsername = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.entities.users.loading);

    const handleSumbit = (e) => {
        e.preventDefault();

        dispatch(getUsername(e.target.email.value));
    };
    
    return ( 
        <Box sx={centerBoxStyle}>
            <Avatar sx={iconAvatarStyle}>
                <ManageAccountsOutlined />
            </Avatar>
            <Typography
                component="h1"
                variant="h5"
            >
                Forgot Username?
            </Typography>
            <Box
                component="form"
                onSubmit={handleSumbit}
                sx={{ mt: 1 }}
            >
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Request Username
                </Button>
                <Backdrop
                    sx={backdropStyle}
                    open={isLoading}
                >
                    <CircularProgress color="primary" />
                </Backdrop>
                <Link to="/about">
                    Having problems? Contact us
                </Link>
            </Box>
        </Box>
     );
}
 
export default ForgotUsername;
export const baseURL = 'https://api2.1098tforms.com/api';

export const apiKey = 'R8DW1bfEPARhP99ApVAJQfjuc';

// verify these catergories
export const nodemailerTransportOptions = {
    host: "*.1098tforms.com",
    port: 587,
    secure: false,
};
export const receivingEmail = "sjoshi@financialpayments.com"
import { Navigate, Route, Routes } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { Container } from '@mui/system';
import Navbar from './components/Navbar';
import Copyright from './components/Copyright';
import Home from './components/Home';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import NotFound from './components/NotFound';
import ForgotPassword from './components/ForgotPassword';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import About from './components/About';
import Products from './components/Products';
import Updates from './components/Updates';
import BudgetingArticle from './components/articles/BudgetingArticle';
import BoostArticle from './components/articles/BoostArticle';
import SaveArticle from './components/articles/SaveArticle';
import EmergencyArticle from './components/articles/EmergencyArticle';
import CreditScoreArticle from './components/articles/CreditScoreArticle';
import CreditCardArticle from './components/articles/CreditCardArticle';
import ForgotUsername from './components/ForgotUsername';
import Instructions from './components/Instructions';

const Content = () => {
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

    return ( 
        <Container
            component="main"
            maxWidth="xl"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
            style={{
                backgroundColor: 'white'
            }}
        >
            <CssBaseline />
            <ToastContainer />
            <Navbar />
            <Routes>
                <Route path="/boost-your-budget-even-in-college" element={<BoostArticle />} />
                <Route path="/budgeting-101-a-4-step-process" element={<BudgetingArticle />} />
                <Route path="/can-you-afford-to-save" element={<SaveArticle />} />
                <Route path="/can-your-budget-withstand-an-emergency" element={<EmergencyArticle />} />
                <Route path="/improving-your-credit-score-5-easy-steps" element={<CreditScoreArticle />} />
                <Route path="/should-i-get-a-credit-card-in-college" element={<CreditCardArticle />} />
                
                <Route path="/help/forgot-password" element={<ForgotPassword />} />
                <Route path="/help/forgot-username" element={<ForgotUsername />} />
                <Route path="/updates" element={<Updates />} />
                <Route path="/products" element={<Products />} />
                <Route path="/about" element={<About />} />
                <Route path="/instructions" element={<Instructions />} />

                <Route path="/sign-up" element={!isAuthenticated ? <SignUp /> : <Navigate replace to="/" />} />
                <Route path="/sign-in" element={!isAuthenticated ? <SignIn /> : <Navigate replace to="/" />} />
                <Route path="/" element={<Home />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
            <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
     );
}
 
export default Content;
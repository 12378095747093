import { LockResetOutlined } from "@mui/icons-material";
import { Avatar, Backdrop, Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { resetPassword } from "../store/users";
import { backdropStyle, centerBoxStyle, iconAvatarStyle } from "../util/styles";

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.entities.users.loading);

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(resetPassword(e.target.username.value));
    };
    
    return ( 
        <Box sx={centerBoxStyle}>
            <Avatar sx={iconAvatarStyle}>
                <LockResetOutlined />
            </Avatar>
            <Typography
                component="h1"
                variant="h5"
            >
                Forgot Password?
            </Typography>
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
            >
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="username"
                    autoFocus
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Submit
                </Button>
                <Backdrop
                    sx={backdropStyle}
                    open={isLoading}
                >
                    <CircularProgress color="primary" />
                </Backdrop>
                <Link to="/help/forgot-username">
                    Forgot username?
                </Link>
            </Box>
        </Box>
     );
}
 
export default ForgotPassword;
import { LockOutlined } from "@mui/icons-material";
import { Avatar, Backdrop, Button, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { loadUser } from "../store/auth";
import { backdropStyle, centerBoxStyle, iconAvatarStyle } from "../util/styles";

const SignIn = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.auth.loading);
    
    const handleLogin = (e) => {
        e.preventDefault();

        const { username, password } = e.target;
        dispatch(loadUser({
            name: username.value,
            password: password.value
        }));
    }

    return ( 
        <Box sx={centerBoxStyle}>
            <Avatar sx={iconAvatarStyle}>
                <LockOutlined />
            </Avatar>
            <Typography
                component="h1"
                variant="h5"
            >
                Sign In
            </Typography>
            <Box
                component="form"
                onSubmit={handleLogin}
                sx={{ mt: 1 }}
            >
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="username"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Sign In
                </Button>
                <Backdrop
                    sx={backdropStyle}
                    open={isLoading}
                >
                    <CircularProgress color="primary" />
                </Backdrop>
                <Grid container>
                    <Grid item xs>
                        <Link to="/help/forgot-password">
                            Forgot password?
                        </Link>
                    </Grid>
                    <Grid item xs>
                        <Link to="/sign-up">
                            {"Don't have an account? Register"}
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </Box>
     );
}
 
export default SignIn;
import { createTheme, ThemeProvider } from '@mui/material';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { themeOptions } from './util/theme';
import BackgroundContainer from './BackgroundContainer';

function App() {
  const theme = createTheme(themeOptions);

  return (
    <ThemeProvider theme={theme}>
      <BackgroundContainer />
    </ThemeProvider>
  );
}

export default App;
export const spacerSpanStyle = {
    marginLeft: 40
};

export const fullSpacerSpanStyle = {
    flex: "1 1 auto"
};

export const blankLinkStyle = {
    textDecoration: "none",
    color: "inherit"
};

export const avatarStyle = {
    width: 45,
    height: 45,
    bgcolor: "secondary.main"
};

export const iconAvatarStyle = {
    m: 1,
    bgcolor: 'secondary.main'
}

export const centerBoxStyle = {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}

export const smallCenterBoxStyle = {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: "sm"
}

export const centerPaperStyle = {
    my: { xs: 6, md: 6 },
    p: { xs: 2, md: 3 }
}

export const backdropStyle = {
    color: '#fff',
    zIndex: (theme) => theme.zIndex.drawer + 1
};

export const menuPaperProps = {
    elevation: 0,
    sx: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0,32)',
        mt: 1.5,
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
        },
        '&:before': {
            content: '""',
            display: "block",
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            pgcolor: 'background.paper',
            transform1: 'translateY(-50%) rotate(45deg)',
            zIndex: 0
        }
    }
};
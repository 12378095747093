import { Check } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { blankLinkStyle, centerBoxStyle } from "../util/styles";
import Subscribe from "./Subscribe";

const Products = () => {
    const solutions = [
        "Student Deferred Payment Plans",
        "Online Payments Processing",
        "Wireless Merchant Terminal Processing",
        "Student Designated Refund Account Choice",
        "Student ID Cards – Financial Debit Cards or NON-Financial Cards",
        "Meal Plan Systems and Closed Loop Wallets"
    ];

    return ( 
        <Box sx={centerBoxStyle}>
            <Typography
                component="h1"
                variant="h4"
                color="secondary"
            >   
                ARE YOU LOOKING FOR A QUICK <br></br>
                AND EASY 1098-T IRS SOLUTION?
            </Typography>
            <Typography
                component="h3"
                variant="h6"
            >
                No ERP Integration Required | Quick and Easy Onboarding | Convenient Student Access | Meets IRS Requirements
            </Typography>
            <br></br>
            <Link to="/sign-up" style={blankLinkStyle}>
                <Button color="primary">
                    <Typography
                        component="h2"
                        variant="h5"
                    >
                        SIGN UP TO LEARN MORE!
                    </Typography>
                </Button>
            </Link>
            <br></br>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography variant="h6">
                        ADDITIONAL CAMPUS SOLUTIONS
                    </Typography>
                    {solutions.map(solution => (
                        <Typography variant="body1" color="info.main">
                            <Check color="success" /> {solution}
                        </Typography>
                    ))}
                </Grid>
                <Grid item xs={6}>
                    <Subscribe />
                </Grid>
            </Grid>
        </Box>
     );
}
 
export default Products;
import { LockOutlined } from "@mui/icons-material";
import { Avatar, Backdrop, Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createUser } from "../store/users";
import { backdropStyle, centerBoxStyle, iconAvatarStyle } from "../util/styles";

const SignUp = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.entities.users.isLoading);

    const handleSignUp = (e) => {
        e.preventDefault();

        const { username, email, password, confirmPassword } = e.target;

        if (password.value !== confirmPassword.value)
            return toast.error("Passwords must match");

        dispatch(createUser({
            name: username.value,
            email: email.value,
            password: password.value
        }));
    }
    return ( 
        <Box sx={centerBoxStyle}>
            <Avatar sx={iconAvatarStyle}>
                <LockOutlined />
            </Avatar>
            <Typography
                component="h1"
                variant="h5"
            >
                Sign Up
            </Typography>
            <Box
                component="form"
                onSubmit={handleSignUp}
                sx={{ mt: 3 }}
            >
                <Grid container spacing={2}>
                    {/* <Grid item xs={12} sm={6}>
                        <TextField
                            autoComplete="given-name"
                            name="firstName"
                            required
                            fullWidth
                            id="firstName"
                            label="First Name"
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                            autoComplete="family-name"
                        />
                    </Grid> */}
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            id="confirmPassword"
                            autoComplete="new-password"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox value="allowExtraEmails" color="primary" />}
                            label="I would like to join the mailing list via email."
                        />
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign Up
                    </Button>
                </Grid>
            </Box>
            <Backdrop
                sx={backdropStyle}
                open={isLoading}
            >
                <CircularProgress color="primary" />
            </Backdrop>
        </Box>
     );
}
 
export default SignUp;
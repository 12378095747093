import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { centerBoxStyle } from "../util/styles";

const NotFound = () => {
    return ( 
        <Box sx={centerBoxStyle}>
            <Typography
                component="h1"
                variant="h4"
                color="error"
                sx={{ mt: 2 }}
            >
                404
            </Typography>
            <Typography
                component="h2"
                variant="h5"
            >
                Page not found.
            </Typography>
        </Box>
     );
}
 
export default NotFound;
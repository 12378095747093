import { Box, Typography } from "@mui/material";
import { centerBoxStyle } from "../../util/styles";
import image from '../../assets/images/article_images/5-min-700x441.png';

const BudgetingArticle = () => {
    return ( 
        <Box sx={centerBoxStyle}>
            <Typography
                component="h1"
                variant="h4"
                color="secondary"
                sx={{ mt: 2 }}
            >
                Budgeting 101: 1 4-Step Process
            </Typography>
            <img src={image} alt="Budgeting 101" />
            <Typography variant="body1">
                Going to college is your gateway into the adult world, and entry into the adult world requires learning how to handle your money like, well, an adult. As a college student, you might not be an entirely self-sufficient adult, but your college years are the perfect time to practice the art of money management.Begin setting yourself up for financial success by learning how to build a college budget.
            </Typography>
            <br></br>
            <Typography
                component="h2"
                variant="h6"
            >
                Step 1: Keep Track of Your Income
            </Typography>
            <Typography variant="body1">
                The first rule of successful money management is to keep your spending below your income. Whether you have a part-time job, grants for your education or you simply earn a few bucks here and there in your spare time, keep track of every penny you make before you spend it. Doing so will not only help you avoid spending money you don’t have, it will also show you, over time, how your income fluctuates throughout college. When you graduate, you’ll have established a good understanding of the amount of money you need to earn in order to maintain a comfortable lifestyle. This knowledge will become crucial when the time comes to choose your first post-grad job.
            </Typography>
            <br></br>
            <Typography
                component="h2"
                variant="h6"
            >
                Step 2: Record and Categorize Your Expenses
            </Typography>
            <Typography variant="body1">
                Some expenses are essential, and you’ll have to learn to accept spending money on them. Food, toiletries, and any bills you are responsible for while you’re in college are all things that have to be paid for. By keeping track of all your essential expenses, you can figure out the minimum amount you must earn each month to stay afloat. On the other hand, there are things you enjoy spending money on, such as new clothes, going out for meals, and going bowling with friends. Although you enjoy these things, you can live without them if necessary. You don’t have to feel guilty about spending money on indulgences, but do keep track of your fun expenses separately so that you know how much money you need to earn for the lifestyle you want to live. Tracking your expenses – both essential and fun – is one of the most important steps of budgeting in college. In the event of a financial emergency, you’ll have a clear picture of what you can sacrifice to cover the cost.
            </Typography>
            <br></br>
            <Typography
                component="h2"
                variant="h6"
            >
                Step 3: Have a Plan and Stick to It
            </Typography>
            <Typography variant="body1">
                By tracking your income and expenses, you’ll be able to establish an earning and spending plan. If you can find a part-time job to earn some income, GREAT! If your studies become more intensive and prevent you from taking on more work, create a step-by-step plan to live cheaply. Eliminate more fun expenses and put a certain amount of money away each month to help you afford the essentials. Your financial situation will change as you go through college, but by starting each month – or each semester – with a practical, achievable financial plan, you can better prepare for whatever expenses may come your way.
            </Typography>
            <br></br>
            <Typography
                component="h2"
                variant="h6"
            >
                Step 4: Open a Checking and Savings Account
            </Typography>
            <Typography variant="body1">
                There are many banks that offer checking and savings accounts established to help students like you accomplish their financial goals. eChecking accounts with low fees are perfect for college students. You can also open a savings account to help you put more money away. The more money you save as a student, the less debt you’ll have when you graduate. Several colleges provide student IDs tied to a Herring Bank eChecking account in what’s called the College Green Accounts program. This program provides a way for students to receive their financial aid money easily and efficiently. If you attend a college that utilizes Herring Bank’s CGA program, be sure to take full advantage of the account and the additional resources it provides. For more information on the College Green Accounts program, visit our website at <a href="www.herringbank.com">www.herringbank.com</a>.
            </Typography>
        </Box>
     );
}
 
export default BudgetingArticle;
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { blankLinkStyle, centerBoxStyle } from '../util/styles';
import Articles from "./Articles";

const Home = () => {
    return ( 
        <Box sx={centerBoxStyle}>
            <Typography
                component="h1"
                variant="h3"
                color="secondary"
            >
                HEY, COLLEGE STUDENTS!
            </Typography>
            <Typography
                component="h2"
                variant="h5"
            >
                ENROLL AND DOWNLOAD YOUR 1098-T IRS FORM.
            </Typography>
            <br></br>
            <Link
                style={blankLinkStyle}
                to="/sign-up"
            >
                <Button
                    color="info"
                    variant="contained"
                >
                    {">>TO STUDENT ENROLLMENT PAGE"}
                </Button>
            </Link>
            <br></br>
            <Typography variant="body1">
                For help enrolling and downloading your 1098-T form, click the link below for detailed instructions.
            </Typography>
            <Link
                style={blankLinkStyle}
                to="/instructions"
            >
                <Button
                    color="info"
                    variant="outlined"
                >
                    {">>TO INSTRUCTIONS PAGE"}
                </Button>
            </Link>
            <br></br>
            <Typography variant="body1">
                {"THE AMERICAN OPPORTUNITY TAX CREDIT ALLOWS TAXPAYERS TO REDUCE THEIR FEDERAL INCOME TAX BASED UPON QUALIFIED TUITION AND FEES. FOR MORE INFORMATION ON THE 1098-T IRS FORM, "}
                <a href="https://www.irs.gov/pub/irs-pdf/i1098et.pdf">click here.</a>
                {"."}
            </Typography>
            <Articles />
        </Box>
     );
}
 
export default Home;
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const users = createSlice({
    name: 'users',
    initialState: {
        users: [],
        loading: false
    },
    reducers: {
        newUserRequested(state, action) {
            state.loading = true;
        },
        newUserSuccess(state, action) {
            state.users.push(action.payload);
            state.loading = false;
        },
        newUserFailed(state, action) {
            state.loading = false;
        },

        passwordResetRequested(state, action) {
            state.loading = true;
        },
        passwordResetSuccess(state, action) {
            state.loading = false;
        },
        passwordResetFailed(state, action) {
            state.loading = false;
        },

        usernameRequestRequested(state, action) {
            state.loading = true;
        },
        usernameRequestSuccess(state, action) {
            state.loading = false;
        },
        usernameRequestFailed(state, action) {
            state.loading = false;
        }
    }
});

const {
    newUserRequested,
    newUserSuccess,
    newUserFailed,

    passwordResetRequested,
    passwordResetSuccess,
    passwordResetFailed,

    usernameRequestRequested,
    usernameRequestSuccess,
    usernameRequestFailed
} = users.actions;

export const usersActions = users.actions;

export default users.reducer;

const url = "/users";

export const createUser = (user) => apiCallBegan({
    url: '/api/auth/signup',
    method: 'POST',
    data: user,
    onStart: newUserRequested.type,
    onSuccess: newUserSuccess.type,
    onError: newUserFailed.type
});

export const resetPassword = (user) => apiCallBegan({
    url: `${url}/forgot-password`,
    method: 'POST',
    data: user,
    onStart: passwordResetRequested.type,
    onSuccess: passwordResetSuccess.type,
    onError: passwordResetFailed.type
});

export const getUsername = (email) => apiCallBegan({
    url: `${url}/forgot-username`,
    method: 'POST',
    data: email,
    onStart: usernameRequestRequested.type,
    onSuccess: usernameRequestSuccess.type,
    onError: usernameRequestFailed.type
});